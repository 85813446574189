<template>
  <v-card flat>
    <v-combobox
      :items="formas_pagamentos"
      item-text="descricao"
      item-value="id"
      :label="$tc('global.formapagamento')"
      placeholder="Selecione uma forma de pagamento"
      clearable
      dense
      outlined
      hide-details
      @click:clear="clear"
      v-model="selectedFormaPagamento"
    ></v-combobox>
  </v-card>
</template>

<script>
import { fetchFormasPagamentos } from "@/api/formas-pagamento/formas_pagamento.js";
export default {
  name: "ComboboxFormasPagamento",

  props: {
    forma_pagamento: {
      type: Object,
    },
    pais: {
      type: Array,
    },
  },

  data() {
    return {
      formas_pagamentos: [],
      selectedFormaPagamento: null,
    };
  },

  watch: {
    selectedFormaPagamento() {
      if (!this.selectedFormaPagamento) return;
      this.$emit("update:forma_pagamento", this.selectedFormaPagamento);
    },
  },

  methods: {
    clear() {
      this.selectedFormaPagamento = null;
      this.$emit("update:forma_pagamento", this.selectedFormaPagamento);
    },

    async getFormasDePagamento() {
      const response = await fetchFormasPagamentos();
      this.selectedFormaPagamento = response.find(
        (item) => item.descricao == "DINHEIRO"
      );
      this.formas_pagamentos = response.map((item) => {
        if (
          item.descricao != "DINHEIRO" &&
          // item.descricao != "DEPOSITO" &&
          item.descricao != "CHEQUE"
        ) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }

        return item;
      });
    },
  },

  async mounted() {
    this.getFormasDePagamento();
  },
};
</script>

<style></style>
